import React from "react"
import { graphql } from "gatsby"
import { getCurrentHomepage } from "@bufox/gatsby-utils/fe/wp"

import PropTypes from "prop-types"
import Layout from "components/layouts/base-layout/base-layout"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import MainContent from "components/main-content/main-content"
import styled from "styled-components"
import ArchivePosts from "components/archive-posts/archive-posts"

const Tag = props => {
  const {
    className,
    location,
    data: {
      wpTag: {
        name,
        description,
        language,
        acfTaxonomy: { featuredImage },
        posts,
        seo,
        translations,
      },
      homepages,
      rawMenus,
      searchUrls,
      wpSettings,
      commonThemeSettings,
      themeSettings: {
        acfThemeSettings: {
          tag: { postsTitle },
        },
      },
    },
  } = props
  const {
    acfThemeSettings: {
      generalSettings,
      footer,
      search,
      socialNetworks,
      socialNetworksShare,
    },
  } = commonThemeSettings

  const homepage = getCurrentHomepage(homepages, language.code)

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={homepage?.uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={description}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={name}
          translations={translations}
        />
        <MainContent
          breadcrumbs={{ breadcrumbs: seo.breadcrumbs, removeLastLink: true }}
          className="tag-content"
          content={description}
          image={featuredImage ? featuredImage.node : null}
          location={location}
          siteUrl={process.env.GATSBY_SITE_URL}
          socialNetworksShare={socialNetworksShare}
          title={name}
        ></MainContent>
        {posts.nodes.length ? (
          <ArchivePosts
            className="all-posts dry-container"
            name={name}
            posts={posts.nodes}
            replaceString="%tag%"
            title={postsTitle}
          ></ArchivePosts>
        ) : null}
      </div>
    </Layout>
  )
}

const StyledTag = styled(Tag)``

Tag.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    homepages: PropTypes.object,
    rawMenus: PropTypes.object,
    wpTag: PropTypes.shape({
      description: PropTypes.string,
      language: PropTypes.object,
      name: PropTypes.string,
      acfTaxonomy: PropTypes.shape({
        featuredImage: PropTypes.object,
      }),
      posts: PropTypes.object,
      seo: PropTypes.object,
      translations: PropTypes.array,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
    themeSettings: PropTypes.object,
  }),
  location: PropTypes.object,
}

export const pageQuery = graphql`
  query Tag(
    $id: String
    $themeSettingsPageId: String
    $menusLocations: [WpMenuLocationEnum]
  ) {
    wpTag(id: { eq: $id }) {
      name
      description
      posts {
        nodes {
          ...DryMiniPostCardInfo
        }
      }
      language {
        code
        id
        locale
        name
        slug
      }
      acfTaxonomy {
        featuredImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      translations {
        id
        uri
        language {
          code
        }
      }
      seo {
        ...WpTaxonomySeoFields
      }
    }
    themeSettings: wpThemeSettingsPage(id: { eq: $themeSettingsPageId }) {
      acfThemeSettings {
        tag {
          postsTitle
        }
      }
    }
    ...CommonThemeSettings
    ...Homepages
    ...LocalizedMenus
    ...SearchUrls
    ...WpSettings
  }
`

export default Tag
export { StyledTag }
