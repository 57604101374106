import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import themeConfig from "utils/theme-config"

const { screens } = themeConfig

const ArchivePosts = props => {
  const { className, name, posts, replaceString, title } = props
  return (
    <div className={className}>
      {title ? <h2>{title.replace(replaceString, name)}</h2> : null}
      <ul>
        {posts.map((element, i) => (
          <li key={`mini-post-${i}`}>
            {element.featuredImage?.node?.localFile?.childImageSharp
              ?.gatsbyImageData ? (
              <GatsbyImage
                image={
                  element.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                className="mini-image"
                alt=""
                loading="lazy"
              />
            ) : null}
            <Link className="post-link" to={element.uri}>
              {element.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

ArchivePosts.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  posts: PropTypes.array,
  replaceString: PropTypes.string,
  title: PropTypes.string,
}

const StyledArchivePosts = styled(ArchivePosts)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    border-left-width: 2px;
    border-color: transparent;
    &:hover {
      border-color: var(--tertiary);
      background-color: var(--gray1);
    }
  }
  .mini-image {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
  .post-link {
    text-transform: uppercase;
    color: var(--primary);
  }

  @media only screen and (min-width: ${screens.lg}) {
    li {
      width: 50%;
    }
  }
  @media only screen and (min-width: ${screens.xl}) {
    li {
      width: 33.333333%;
    }
  }
`
export { ArchivePosts }
export default StyledArchivePosts
